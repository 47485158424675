<template>
  <div>
    <RMPageHeader>
      <template v-slot:header>
        {{ pageTitle }}
        <small>
          <b-link v-on:click="navigateToMyAssignmentsPage()">({{ $t("assignments.myAssignments") }})</b-link>
        </small>
      </template>
    </RMPageHeader>
    <div v-if="loading" class="row m-t-20">
      <div class="col-12">
        <b-skeleton class="height-200"></b-skeleton>
      </div>
    </div>
    <div v-else-if="currentTaskIdx === -1">
      <div class="row text-center">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8">
              <div v-if="assignment?.studentMessage">
                <h4>{{ $t("assignments.fields.messageFromMentor") }}</h4>
                <hr/>
                <div class="alert alert-secondary">{{ assignment.studentMessage }}</div>
                <hr/>
              </div>
              <h4>{{ $t("assignments.tasks") }}</h4>
              <hr/>
              <div class="row content-center">
                <div
                  class="col"
                  v-for="(task, idx) in tasks"
                  :key="idx"
                  :style="getTaskPanelStyle()"
                  v-on:click="onTaskClick(task, idx)"
                >
                  <RMPanel :title="getTaskTitle(idx)" :text-center="true">
                    <template v-slot:panelBody>
                      <i
                        v-if="task?.completed && task?.correct"
                        class="fa fa-check fa-solved-rm fa-2x" aria-hidden="true"
                      />
                      <i
                        v-else-if="task?.completed"
                        class="fa fa-times fa-wrong-rm fa-2x" aria-hidden="true"
                      />
                      <i
                        v-else
                        class="fa fa-question fa-default-rm fa-2x" aria-hidden="true"
                      />
                    </template>
                  </RMPanel>
                </div>
              </div>
            </div>
            <div class="col-lg-2"></div>
          </div>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-lg-3"></div>
        <div v-if="!completed" class="col-lg-6 alert alert-secondary">
          <i class="fa fa-exclamation-triangle m-r-5" aria-hidden="true"></i>
          <span style="font-size: medium">{{ $t("assignments.noteOneAnswer") }}</span>
        </div>
        <div v-else class="col-lg-6 alert rm-solved">
          <span style="font-size: medium">{{ $t("assignments.allTasksCompleted") }}</span>
        </div>
        <div class="col-lg-3"></div>
      </div>
    </div>
    <div v-else>
      <div class="row text-center">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-3"/>
            <div class="col-lg-6">
              <MathGridContent :is-assignment="true"/>
            </div>
            <div class="col-lg-3"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RMPageHeader from "@/components/common/RMPageHeader.vue";
import RMPanel from "@/components/common/RMPanel.vue";
import {mapState} from "vuex";
import MathGridContent from "@/pages/games/education/MathGridContent.vue";
import {getMyAssignmentsRoute} from "@/_helpers/routes_helper";

export default {
  name: "MyAssignmentDispatch",
  components: {MathGridContent, RMPanel, RMPageHeader},
  data() {
    return {
      assignmentId: this.$route.params.assignmentId,
    }
  },
  computed: {
    ...mapState("mathStore", {
      assignment: (state) => state.assignment,
      tasks: (state) => state.tasks,
      currentTaskIdx: (state) => state.currentTaskIdx,
      loading: (state) => state.loading,
      allFilledUp: (state) => state.allFilledUp,
      hasWrongValues: (state) => state.hasWrongValues,
      completed: (state) => state.completed,
    }),
    pageTitle() {
      return this.assignment?.name || "";
    }
  },
  methods: {
    navigateToMyAssignmentsPage() {
      this.$router.push(getMyAssignmentsRoute());
    },
    getTaskTitle(idx) {
      return idx + 1
    },
    getTaskPanelStyle() {
      return { cursor: "pointer" }
    },
    onTaskClick(task, idx) {
      this.$store.commit("mathStore/goToTask", {
        taskIdx: idx,
      });
    },
  },
  created() {
    this.$store.commit("mathStore/initGrid", {
      game: "assignment",
      gameId: this.assignmentId,
      isAssignment: true,
    });
  },
}
</script>